@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@300;400;500;600;800&family=Poppins:wght@400;500;600;700&display=swap');
@font-face {
    font-family: "kyv";
    src: url("./kyiv-type-serif/KyivTypeSerif-VarGX.ttf");
    }
    @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@300;400;500;600;800&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Poppins:wght@500;600;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@300;400;500;600;700&family=Manrope:wght@300;400;500;600;800&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Poppins:wght@500;600;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
body{
    
    overflow-X: hidden;
background: #1a1919;

    ;
}
html {
    overflow-x: hidden;
  }
  
  body {
    position: relative;
    overflow-x: hidden;

  }
.main_sec{
    width: 80%;
    margin: auto;
    margin-top: 2%;
}
.x_row1{
    display: flex;
    justify-content: space-between;
    align-items: center; 
}
.x_col{
    flex-basis: 45%;
}
.x_col img{
    width: 100%;
}
.x_col h1{
    font-family: 'kyv', Arial, sans-serif;

font-size: 70px;
font-weight: 500;
line-height: 106px;
letter-spacing: 0em;
text-align: left;
color: #fff;
}
.x_col p{
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: justified;
    color: #FFFFFFAB;
    
}
.x_col h2{
    font-family: Open Sans;
font-size: 45px;
font-weight: 700;
line-height: 62px;
letter-spacing: 0em;
text-align: left;
color: #fff;
}
.x_col button{
    font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
background: #F6BB5B;
border-radius: 20px;
padding: 10px;
margin-top: 4%;
}
.main_sec2,.main_sec3{
    width: 80%;
    margin: auto;
}
.x_col1{
    flex-basis: 31%;
    background: linear-gradient(90deg, #292828 0%, rgba(255, 255, 255, 0) 102.95%),
linear-gradient(91.02deg, #020302 -1.57%, rgba(66, 66, 66, 0) 247.47%);

border-image-source: linear-gradient(90deg, #000000 0%, rgba(255, 255, 255, 0) 102.95%);
padding: 20px;
border: 1.5px solid;

border-image-source: linear-gradient(90deg, #000000 0%, rgba(255, 255, 255, 0) 102.95%);


}
a:link { 
    text-decoration: none; 
  } 
  a:visited { 
    text-decoration: none; 
  } 
  a:hover { 
    text-decoration: none; 
  } 
  a:active { 
    text-decoration: none; 
  }
.x_r2{
    display: flex;
    justify-content: space-between;
    align-items: center; 
}
.x_colh{
flex-basis: 48%;
}
.x_colh img{
    width: 100%;
}
.x_colh h2{
    font-family: Inter;
font-size: 24px;
font-weight: 500;
line-height: 50px;
letter-spacing: 0em;
text-align: left;
color: #fff;
;
}
.x_col1 p{
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: justified;
margin-top: 5%;    
}
.row_mlst,.row_mlst2{
    display: flex;
    justify-content: space-between;
    align-items: center; 
}
.col_mlst{
    flex-basis: 49%;
}
.col_mlst h3{
    font-family: Open Sans;
font-size: 22px;
font-style: italic;
font-weight: 300;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFFAB;
}
.col_mlst h2{
    font-family: Open Sans;
font-size:30px;
font-weight: 700;
line-height: 42px;
letter-spacing: 0em;
text-align: left;
color: #fff;
}
.col_mlst p{
    font-family: Open Sans;
font-size: 22px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFFAB;

}
#xmlst{
    font-family: Open Sans;
font-size: 26px;
font-style: italic;
font-weight: 300;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF;

}
.col_mlst2{
    flex-basis: 30%;
    margin-top: 2%;
}
.col_mlst2 p{
    font-family: Open Sans;
font-size: 16px;
font-weight: 400;
line-height: 28px;
letter-spacing: 0em;
text-align: justified;

}
.lstpic{
    position: relative;
    background: #fff;
}
.lstpic img{
    width: 100%;
    position: absolute;
    
    background: #fff;
}
.lstpic div{
    position: absolute;
    background: #fff;
    margin-top: 30%;
    width: 30%;
    margin-left: 20%;
    padding: 25px;
}
.lstpic div h2{
    font-family: Open Sans;
font-size: 28px;
font-style: italic;
font-weight: 300;
line-height: 38px;
letter-spacing: 0em;
text-align: left;

}
.lstpic div p{
    font-family: Open Sans;
font-size: 16px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;

}
.lstpic div button{
    font-family: Inter;
font-size: 16px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
background: #F6BB5B;
padding: 10px;

}
.lastmain{
    width: 80%;
    margin: auto;
    padding-top: 70%;
}
.lst_row,.rowwlst{
    display: flex;
    justify-content: space-between;
    align-items: center; 
}
.lst2_col{
    flex-basis: 49%;
}
.lst2_col button{
    font-family: Inter;
font-size: 14px;
font-weight: 600;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
background: #232323;
padding: 10px;
color: #fff;

}
#btn2{
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: right;
    padding: 10px;
    color: #000000;
    background: #F6BB5B;
    margin-left: 70%;
}
.lst2_col h3{
    font-family: Inter;
font-size: 22px;
font-weight: 600;
line-height: 35px;
letter-spacing: 0em;
text-align: left;
color: #fff;
}
.colll{
    flex-basis: 48%;
    border: 2px solid #7c7171;
    padding: 20px;
 
}
.colll h2{
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}
.colll p{
    font-family: Inter;
    font-size: 20px;
    font-weight: 300;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}
#col1a{
    border: 2px solid #7c7171;
    padding: 20px;
    font-family: Inter;
font-size: 20px;
font-weight: 300;
line-height: 34px;
letter-spacing: 0em;
text-align: left;
color: #fff;
width: 50%;
margin-left: 25%;
}
#col1a h2{
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}
#col1a p{
    font-family: Inter;
    font-size: 20px;
    font-weight: 300;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}
.mainlast2{
    width: 80%;
    margin: auto;

    border: 2px solid #E2AC56;
border-radius: 10px;
border-color: linear-gradient(90deg, #75664c 10%, rgba(226, 172, 86, 0) 102.95%);



background: linear-gradient(0deg, #000000, #000000),
linear-gradient(90deg, #E2AC56 0%, rgba(226, 172, 86, 0) 102.95%);
text-align: center;
padding: 20px;

}
.mainlast2 h3{
    font-family: Open Sans;
font-size: 54px;
font-style: italic;
font-weight: 300;
line-height: 74px;
letter-spacing: 0em;
text-align: center;
color: #fff;
}
.mainlast2 p{
    font-family: Inter;
font-size: 18px;
font-weight: 500;
line-height: 33px;
letter-spacing: 0em;
text-align: center;
color: #fff;
}
.mainlast2 button{
    font-family: Inter;
font-size: 16px;
font-weight: 400;
padding: 10px;
line-height: 22px;
letter-spacing: 0em;
text-align: center;
background: #000000;
color:#fff;
border: 1.5px solid #F6BB5B
}
#textbx2{

font-family: DM Sans;
font-size: 24px;
font-weight: 700;
line-height: 35px;
letter-spacing: 0px;
text-align: left;

}
#textbx3{
    font-family: "Open Sans", sans-serif;
font-size: 18px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;

}
#textbx4{
font-family: DM Sans;
font-size: 34px;
font-weight: 700;
line-height: 66px;
letter-spacing: 0px;
text-align: left;

}
#textbx5{
    font-family: "Open Sans", sans-serif;
font-size: 20px;
font-style: italic;
font-weight: 300;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
color: #000000

;

}
#textbx7{
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0px;
text-align: left;

}
#textbx8{
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 5%;

}
@media (max-width:880px) {
    .main_sec{
        margin-top: 10%;
    }
    .x_row1{
        flex-direction: column;
        
    }
.x_col img{
    display: none;
}
.x_col1{
    margin-top: 10px;
}
#cpicx{
    display: block;
    margin-top: 15px;
}
.row_mlst,.row_mlst2{
    flex-direction: column;
}
.lstpic div{
    position: relative;
    margin-top: 6%;
}
.lastmain{
    width: 80%;
    margin: auto;
    padding-top: 5%;
}
.lst_row,.rowwlst{
    flex-direction: column;
}
#btn2{
    margin-left: 0%;

}
.lst2_col button{
margin-left: 0%;
}
.colll{
    margin-top: 34px;
}
#col1a{

width: 100%;
margin-left: 0%;
}
.lstpic img{
      
    display: none;
    width: 100%;
    position: absolute;
    
    background: #fff;
}
.lstpic div{
    position: relative;
    background: #fff;
    margin-top: 0%;
    width: 80%;
    margin-left:10%;
   margin-bottom: 5%;
}
}
