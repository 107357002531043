
.con_center{
    width: 80%;
    margin: auto;
    border: 2px solid #E8B157;
    border-radius:  10px;
    padding: 30px;
}

.con_center h2{
    font-family: Manrope;
font-size: 40px;
font-weight: 700;
line-height: 55px;
letter-spacing: -0.5px;
text-align: left;


}
.con_center h2{
    font-family: Poppins;
font-size: 54px;
font-weight: 600;
line-height: 36px;
letter-spacing: 0em;
text-align: justified;
color: #fff;    

}

.con_row{
    display: flex;
    justify-content: space-between;
    align-items: center; 
}
.con_col{
flex-basis: 29%;
background: #E8B157;
padding: 20px;
}
.con_col h2{
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
color: #fff;    
}
.con_col p{
    font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
color: #fff;
}
.con_col2{
    flex-basis: 60%;
    }

    .con_rowico{
        display: flex;
        justify-content: space-between;
        align-items: center; 
    }
    .can_colico{
    flex-basis: 10%;
    text-align: left;
    }
    .can_col2ico{
        flex-basis: 90%;
        text-align: left;
        }   

   .can_col2ico a, .can_colico i,.can_col2ico p{
    font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
color: #fff;
   }     


   .rowinp{
    display: flex;
    justify-content: space-between;
    align-items: center; 

   }
   .colinp{
flex-basis: 49%;
color: #fff;    
   }
   .colinp label,.con_col2 label,.mb label{
    font-family: Poppins;
font-size: 20px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: #fff;    
   }
   .colinp input,.con_col2 input,.mb input{
    border-top: none;
    border-left: none;
    border-right:none ;
    width: 100%;
    color: #fff;    
   }
   .mb{
    display: none;
   }
   .mb button{
    padding: 2px;
    background: #2A4B70;
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    width: 100%;

    }
   .con_col2 button{
margin-left: 70%;
padding: 2px;
background: #2A4B70;
color: #fff;
font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
width: 30%;
}

@media (max-width:1200px) {
    .con_row{
        flex-direction: column;
    }
    .rowinp{
        flex-direction: column;
        display: none;
    }
    .con_col2 button,.con_col2 label{
        display: none;
    }
    .mb {
        display: block;
    }
    .row__conlst{
flex-direction: column;
    }
    .can_conlst,.can_conlst2,.can_conlst3{
margin-top: 5%;
    }
    .can_conlst p,.can_conlst2 p,.can_conlst3 p{
        text-align: center;
        margin-bottom: 4%;
    }
}

