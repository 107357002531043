.blog_mid{
    width: 80%;
    margin: auto;

}
.blog_mid h3{
    font-family: Nunito Sans;
font-size: 48px;
font-weight: 700;
line-height: 71px;
letter-spacing: 0.02em;
text-align: center;
color: #F6BB5B;
;
}
.blog_row,.blog2_row{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.blog_col{
    flex-basis: 32%;
}
.blog_col img{
    width: 100%;
}
.blog_col h4{

font-family: Sen;
font-size: 24px;
font-weight: 600;
line-height: 40px;
letter-spacing: -1px;
text-align: left;
color: #fff;
margin-top: 1%;
}
.blog_col p{

font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 28px;
letter-spacing: 0px;
text-align: left;
color: #fff;
}
.blog_mid_2{
    width: 80%;
    margin: auto;

}
.blog_mid_2 h2{
    font-family: Nunito Sans;
font-size: 48px;
font-weight: 700;
line-height: 71px;
letter-spacing: 0.02em;
text-align: center;
color: #F6BB5B;

}
.blog2_col{
    flex-basis: 19%;
}
.blog2_col img{
    width: 70%;
}