@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
.abtmin{
    width: 80%;
    margin: auto;
}
.about_row{
    display: flex;
    justify-content: space-between;
   
}
.abcol1{
    flex-basis: 30%;

}
.abcol1b{
    flex-basis: 70%;
    
}
.abcol1 h2,.abcol1b h2{
    font-family: "Kumbh Sans", sans-serif;
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
color: #ffff;    
}
.abcol1b p{
    font-family: "Kumbh Sans", sans-serif;
    margin-top: 3px;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffff;

}
.abcol2{
    flex-direction: 33.33%;
}
.abcol2 img{
    width: 100%;
}
@media (max-width:880px) {
    .about_row{
        flex-direction: column;

    }
}