.slidee{
    background: #F6BB5B;
padding: 90px;
border-radius: 15px;
margin-top: 30px;
}
.slrow{
    display: flex;
    justify-content: space-between;
  
    align-items: center;

}
.slrow_cola{
    flex-basis: 24%;
}
.slrow_cola img{
    width: 100%;
    border-radius: 50%;
   
}
.slrow_colb{
    flex-basis: 72%;
}
.slrow_colb h2{
    font-family: Inter;
font-size: 38px;
font-weight: 500;
line-height: 22px;
letter-spacing: 0em;

color: black;
}
.slrow_colb p{
    font-family: Inter;
font-size: 18px;
font-weight: 500;
margin-top: 20px;

color: black;
}
@media (max-width:880px) {
    .slrow{
        flex-direction: column;
        justify-content: space-between;
      
        align-items: center;
    
    }
    .slrow_cola img{
        width: 100%;
        margin-bottom: 20%;
    }
}