.member_sec{
    width: 80%;
    margin: auto;
    text-align: center;
}
.member_sec h4{
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1em;
 
    color: #F6BB5B;
    ;
}
.member_sec h2{

    font-family: DM Sans;
    font-size: 50px;
    font-weight: 700;
    line-height: 62px;
    letter-spacing: 0px;
    text-align: center;
    color: #fff;
}
.member_sec p{
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    
}

.mem_row {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
}
 .mem_cola{
    flex-basis: 24%;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
linear-gradient(0deg, #EFF0F7, #EFF0F7);
border: 1px solid #EFF0F6;
box-shadow: 0px 2px 12px 0px #14142B14;
text-align: left;
padding: 30px;
    border-radius: 20px;
    margin-top: 120px;
}
.mem_colb{
    flex-basis: 24%;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
linear-gradient(0deg, #EFF0F7, #EFF0F7);
border: 1px solid #EFF0F6;
box-shadow: 0px 2px 12px 0px #14142B14;
text-align: left;
padding: 30px;
margin-top: 0%;
    border-radius: 20px;
    background-color: rgb(230, 221, 221);


}
.mem_colbca{
    background-color: rgb(230, 221, 221);
    text-align: center;
    flex-basis: 45%;
    padding: 20px;

}
.mem_colbca img{
width: 50%;
}

.mem_colbcd{
    text-align: left;
    flex-basis: 40%;

}
.mem_colbcd p{
    font-family: Open Sans;
font-size: 12px;
font-style: italic;
font-weight: 300;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: black;

}
.mem_colbcd h3{

font-family: DM Sans;
font-size: 16px;
font-weight: 700;
line-height: 35px;
letter-spacing: 0px;
text-align: left;
color: black;

}
.mem_cola p,.mem_colb p{
    font-family: Open Sans;
font-size: 18px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
color: black;
}
.mem_cola h3,.mem_colb h2{

font-family: DM Sans;
font-size: 34px;
font-weight: 700;
line-height: 66px;
letter-spacing: 0px;
text-align: left;
color: black;
}
.mem_cola h2{
color: black;
font-family: DM Sans;
font-size: 29px;
font-weight: 700;
line-height: 66px;
letter-spacing: 0px;
text-align: left;

}
.mem_cola ul{

font-family: DM Sans;
font-size: 24px;
font-weight: 500;
line-height: 66px;
letter-spacing: 0px;
text-align: left;

}
#chek{
    display: flex;
align-items: center;
margin-top: 2%;
}
#chek img{
    width: 8%;
    height: 20px;
}
#chek p{
    font-family: Open Sans;
font-size: 18px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0px;
text-align: left;
margin-left: 4%;
color: black;
}
#bttn{

font-family: DM Sans;
font-size: 16px;
font-weight: 700;
line-height: 18px;
letter-spacing: 0em;
text-align: center;
background: #F6BB5B;
padding: 10px;
width: 100%;
border-radius: 20px;
border-color: #F6BB5B;

background-color: #F6BB5B !important;
}

@media (max-width:880px) {
    .mem_row{
        flex-direction: column;

    }
    .mem_colb{
        margin-top: 5%;
    }
}