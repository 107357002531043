@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Kumbh+Sans:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
.mid_faq{
    width: 80%;
    margin: auto;
}
.faq_chat_row{
    display: flex;
    align-items: center;
    border: 1px solid #F6BB5B;
    padding: 20px;
    border-radius: 10px;
    margin-top: 2%;
}
.faq_chat_cola{
    flex-basis: 5%;
    text-align: center;
    display: flex;
}
.faq_chat_cola i{
    color: #fff;
}
.faq_chat_colb{
    flex-basis: 95%;
}
.faq_chat_colb h4{

font-family: Inter;
font-size: 16px;
font-weight: 600;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: #fff;
}
.vl {
    border-left: 2px solid #F6BB5B;
    height: 30px;
  }

  details{
    border: 1px solid #F6BB5B;   
     color: #FFF;
      font-family: DM Sans;
  
    font-size: 1.5rem;
  }
  
  summary {
    font-family: "Inter", sans-serif;
    font-size: 19px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    
    padding: .5em 1.3rem;
    list-style: none;
    display: flex;
    justify-content: space-between;  
    transition: height 3s ease;
    color: #F6BB5B;
  }
  
  summary::-webkit-details-marker {
    display: none;
  }
  
  summary:after{
    content: "\002B";
  }
  
  details[open] summary {
      border-bottom: 1px solid #F6BB5B;
      margin-bottom: .5em;
      color: #F6BB5B;
  }
  
  details[open] summary:after{
    content: "\00D7";
    
  }
  
  details[open] div{
    font-family: "Inter", sans-serif;

font-size: 16px;
font-weight: 600;
line-height: 20px;
letter-spacing: 0em;
text-align: left;

    padding: .5em 1em;
    color: white;
  }
  