.event_mid1{
    width: 80%;
    margin: auto;
}
.header__event{
    display: flex;
    align-items: center;
}

.header__event a{
    font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
color: #fff ;
margin-left: 5%;
}
#bta1{
    background: #E8B157;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
color: black; 
border-radius: 10px;
padding: 10px; 
margin-left: 25%;  
}
#bta2{
    background: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
color: black; 

padding: 10px; 

}
.eve_row1{
    display: flex;
}
.eve_row1 div{
    flex-basis: 24%;
}
.eve2{
    background: #A76D11;

}
.eve_sub{
    width: 80%;
    margin: auto;
}
.eve_sub h2{
text-align: center;

font-family: Poppins;
font-size: 48px;
font-weight: 500;
line-height: 56px;
letter-spacing: -0.02em;
text-align: center;
margin-top: 10%;

}

.eve_row2{
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 80px;
}
.eve_row2 div{
    flex-basis: 19%;
    background: #fff;
    padding: 20px;
}
.eve_row2 div h3{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 2%;
    
}
