.mid_service{
    width: 80%;
    margin: auto;
}
.mid_service p{
    font-family: Inter;
font-size: 18px;
font-weight: 500;
line-height: 22px;
letter-spacing: 0em;
text-align: center;
color: #fff;
}
.mid_service h3{
    font-family: Poppins;
font-size: 40px;
font-weight: 700;
line-height: 48px;
letter-spacing: 0em;
text-align: center;
color: #fff;
}
.service_row{
    display: flex;
    justify-content: space-between;
    
}
.ser_col{
flex-basis: 32%;
border: 1px solid #F6BB5B;
border-radius: 10px;
padding: 20px;

}
.ser_colb{
    flex-basis: 32%;
    border: 1px solid #F6BB5B;
    border-radius: 10px;
    padding: 20px;
    background: #F6BB5B;
    }
.ser_col h3{
    font-family: Poppins;
font-size: 25px;
font-weight: 600;
line-height: 32px;
letter-spacing: 0em;
text-align: left;
color: #fff;
}
.ser_col p,.ser_colb p{
    font-family: Poppins;
font-size: 15px;
font-weight: 300;
line-height: 32px;
letter-spacing: 0em;
text-align: left;
color: #fff;
}
.ser_col h4{
    font-family: Inter;
font-size: 18px;
font-weight: 600;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
color: #F6BB5B;
;
}
.ser_colb h3{
    font-family: Poppins;
font-size: 25px;
font-weight: 600;
line-height: 32px;
letter-spacing: 0em;
text-align: left;
color:black;
}
.ser_colb h4{
    font-family: Inter;
font-size: 18px;
font-weight: 600;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
color:black;
}

@media (max-width:880px) {
    .service_row{
        flex-direction: column;
    }
    .ser_col,.ser_colb{
        margin-top: 5%;
        margin-bottom: 6%;
    }
}