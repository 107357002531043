.career_sec{
   width: 80%;
   margin: auto;
   text-align: center; 
}
.career_sec h3{
    font-family: Poppins;
font-size: 36px;
font-weight: 600;
line-height: 54px;
letter-spacing: 0em;
text-align: center;
color: #fff;
}
.career_sec p{
    font-family: Poppins;
font-size: 18px;
font-weight: 500;
line-height: 27px;
letter-spacing: 0em;
text-align: center;
color: #fff;
}
.car_row{
    display: flex;
    justify-content: space-between;
    
}
.car_col{
    background: #F6BB5B;
flex-basis: 24%;
padding: 40px;
}
.car_col h4{
    font-family: Poppins;
font-size: 24px;
font-weight: 500;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
color: black;

}
.car_col p{
    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: black;
}
.sec_mid{
    width: 80%;
    margin: auto;
}
.sec_mid h2{
    font-family: Poppins;
font-size: 36px;
font-weight: 600;
line-height: 54px;
letter-spacing: 0em;
text-align: center;
color: #fff;
}
.car_ro{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.col_r{
    flex-basis: 33%;
    text-align: center;
}
.col_r img{

}
.col_r h3{
    font-family: Poppins;
font-size: 24px;
font-weight: 500;
line-height: 36px;
letter-spacing: 0em;
text-align: center;
color: white;
margin-top: 4%;
}
.col_r p{
    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
color: #fff;
margin-top: 2%;
}
.cr_sec3 {
    width: 80%;
    margin: auto;
}
.cr_sec3 h2{
    font-family: Poppins;
font-size: 36px;
font-weight: 600;
line-height: 54px;
letter-spacing: 0em;
text-align: left;
color: #fff;
}
.cr_sec3 p{
    font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: #fff;
max-width: 600px;
}
.cr_sec3_r{
    display: flex;
    align-items: center;
    justify-content: space-between;  

}
.col_sec3_r{
    flex-basis: 49%;
    background: #F6BB5B;
padding: 18px;
height: 180px;
}
.col_sec3_r h3{
    font-family: Poppins;
font-size: 20px;
font-weight: 500;
line-height: 30px;
letter-spacing: 0em;
text-align: left;

}
.col__sub_c1{
    flex-basis: 79%;
}
.col__sub_c2{
    flex-basis: 19%;
}
@media (max-width:880px) {
 
    .car_row,.car_ro,.col_sec3_r,.cr_sec3_r{
        flex-direction: column;
    }
    .col_sec3_r{
        margin-top: 29px;
    }

}